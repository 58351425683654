<template>
  <div class="widget-view">
    <component
      v-if="component"
      :is="component"
      ref="dynamicComponent"
      class="widget-view__content"
    ></component>
    <div v-else class="widget-view__loading">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import { coreComponents } from '../config/componentLoader';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      component: null,
    };
  },
  watch: {
    name: 'loadComponent',
    $route: {
      handler: 'handleQueryParams',
      immediate: true,
    },
  },
  async created() {
    await this.loadComponent();
    this.handleQueryParams();
  },
  methods: {
    async loadComponent() {
      if (coreComponents[this.name]) {
        try {
          const module = await coreComponents[this.name]();
          this.component = module.default || module;
        } catch (error) {
          console.error('Failed to load component:', error);
          this.component = null;
        }
      } else {
        console.warn(`Component with name ${this.name} not found.`);
        this.component = null;
      }
    },
    handleQueryParams() {
      const { query } = this.$route;
      this.$nextTick(() => {
        this.invokeMethodsFromQuery(query);
      });
    },
    invokeMethodsFromQuery(query) {
      const componentInstance = this.$refs.dynamicComponent;
      console.log('invokeMethodsFromQuery', query, componentInstance);

      if (componentInstance) {
        Object.keys(query).forEach((methodName) => {
          console.log('component instance', componentInstance, methodName);
          if (typeof componentInstance[methodName] === 'function') {
            try {
              console.log('xvf', 'method', query, typeof query[methodName]);
              const argString = query[methodName];
              console.log('xvf', 'argString', argString.substring(1, -1));
              let args = [];

              if (argString) {
                try {
                  args = argString.substring(1, argString.length - 1).split(',');
                } catch (error) {
                  console.warn(
                    `Invalid JSON for method arguments: ${argString}`,
                  );
                }
              }
              console.log('xvf', 'args', args);
              componentInstance[methodName](...args);
            } catch (error) {
              console.error(`Failed to invoke method ${methodName}:`, error);
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-view {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
  box-sizing: border-box;

  &__content {
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  &__loading {
    font-size: 1.5rem;
    color: #777;
  }
}
</style>
